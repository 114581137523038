/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Inter_100Thin = require('./Inter_100Thin.ttf');
export const Inter_200ExtraLight = require('./Inter_200ExtraLight.ttf');
export const Inter_300Light = require('./Inter_300Light.ttf');
export const Inter_400Regular = require('./Inter_400Regular.ttf');
export const Inter_500Medium = require('./Inter_500Medium.ttf');
export const Inter_600SemiBold = require('./Inter_600SemiBold.ttf');
export const Inter_700Bold = require('./Inter_700Bold.ttf');
export const Inter_800ExtraBold = require('./Inter_800ExtraBold.ttf');
export const Inter_900Black = require('./Inter_900Black.ttf');
